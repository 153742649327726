.container {
  overflow: hidden;

  [data-ellipsis-element] {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
